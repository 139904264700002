  export default [
    {
      value: "COMMERCE",
      text: "Commerce",
      icon: "DollarSignIcon",
      color: "info"
    },
    {
      value: "RH",
      text: "RH",
      icon: "UserIcon",
      color: "success"
    },
    {
      value: "TECHNIQUE",
      text: "Technique",
      icon: "ToolIcon",
      color: "danger"
    },
    {
      value: "ADMINISTRATIF",
      text: "Administratif",
      icon: "FileTextIcon",
      color: "success gradient"
    },
    {
      value: "MÉTIERS",
      text: "Métiers",
      icon: "BriefcaseIcon",
      color: "warning"
    },
    {
      value: "AUTRE",
      text: "Autre",
      icon: "MoreHorizontalIcon",
      color: "secondary"
    }
  ]