








































































































































































import { PaginatedList } from "@/api/models/common/paginatedList";
import { Contact } from "@/api/models/contacts/contact";
import { Partenaire } from "@/api/models/partenaires/partenaire";
import CreateContact from "./CreateContact.vue";
import UpdateContact from "./EditContact.vue";
import {
  BButton,
  BCard,
  BCol,
  BAvatar,
  BDropdown,
  BFormSelect,
  BDropdownItem,
  BFormInput,
  BPagination,
  BRow,
  BTable,
} from "bootstrap-vue";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import Categories from "@/api/models/enums/categoriesEnum";

@Component({
  components: {
    BAvatar,
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    CreateContact,
    UpdateContact,
  },
})
export default class ListContacts extends Vue {
  @Prop({ required: true }) partenaire!: Partenaire;
  perPage = 10;
  currentPage = 1;
  perPageOptions = [2, 5, 10, 25, 50, 100];
  isAddNewContactSidebarActive = false;
  isUpdateContactSidebarActive = false;
  selectedContactId = "";

  tableColumns = [
    { key: "nom", sortable: true },
    { key: "fonction", sortable: true },
    { key: "categorie", sortable: true },
    { key: "telephone1", sortable: true },
    { key: "actions" },
  ];
  paginatedListContacts = new PaginatedList<Contact>();

  categories = Categories;
  categoriesIcons: any = {};
  categoriesColors: any = {};
  categoriesLabels: any = {};

  async created() {
    this.categories.forEach(
      (x: any) => (this.categoriesIcons[x.value] = x.icon)
    );
    this.categories.forEach(
      (x: any) => (this.categoriesColors[x.value] = x.color)
    );
    this.categories.forEach(
      (x: any) => (this.categoriesLabels[x.value] = x.text)
    );
    await this.loadListContact({
      pageNumber: this.currentPage,
      pageSize: this.perPage,
    });
  }

  get paginationFrom() {
    return (
      this.perPage * (this.currentPage - 1) +
      (this.paginatedListContacts.items.length ? 1 : 0)
    );
  }
  get paginationTo() {
    return (
      this.perPage * (this.currentPage - 1) +
      this.paginatedListContacts.items.length
    );
  }

  @Watch("currentPage")
  @Watch("perPage")
  async paginationChanged() {
    await this.loadListContact({
      pageNumber: this.currentPage,
      pageSize: this.perPage,
    });
  }

  selectContact(contactId: string) {
    this.selectedContactId = contactId;
    this.isUpdateContactSidebarActive = true;
  }

  async loadListContact(params: any) {
    if (!params || params?.reset) {
      this.paginatedListContacts = new PaginatedList<Contact>();
    }
    await this.$http.myressif.partenaires
      .getListContactsByPartenaireId(
        this.$route.params.id,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.orderBy,
        params?.orderByDescending,
        params?.search ?? ""
      )
      .then((response: PaginatedList<Contact>) => {
        this.paginatedListContacts = response;
      });
  }

  askDelete(id: string) {
    this.$bvModal
      .msgBoxConfirm("Etes-vous sûr de vouloir supprimer ce contact ?", {
        title: "Confirmation de suppression",
        size: "sm",
        okVariant: "primary",
        okTitle: "Oui",
        cancelTitle: "Non",
        cancelVariant: "outline-secondary",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value === true) {
          await this.confirmDelete(id);
        } else {
          return;
        }
      });
  }

  async confirmDelete(id: string) {
    await this.$http.myressif.partenaires
      .deleteContactPartenaire(this.$route.params.id, id)
      .then(
        async (response: string) => {
          successAlert.fire({
            title: "Suppression d'un contact",
            text: "Suppression effectuée avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(async () => {
        await this.loadListContact({
          pageNumber: this.currentPage,
          pageSize: this.perPage,
        });
      });
  }
}
