





























































































































































































































import {
  BForm,
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BRow,
  BSpinner,
  BFormSelect,
  BIcon,
  BIconPencilFill,
  VBTooltip,
} from "bootstrap-vue";
import { Component, Vue } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import { Partenaire } from "@/api/models/partenaires/partenaire";
import ListContacts from "./Contacts/Contacts.vue";
import ListContrats from "./Contrats/Contrats.vue";
import ListInformationsAdditionnelles from "../informationAdditionnelles/ListInformationsAdditionnelles.vue";
import { TypeEntiteEnum } from "@/api/models/enums/typeEntiteEnum";

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BSpinner,
    BForm,
    BFormGroup,
    BFormRadio,
    BFormCheckbox,
    BFormSelect,
    BIcon,
    ValidationProvider,
    ValidationObserver,
    SearchableVueSelect,
    ListContacts,
    ListContrats,
    ListInformationsAdditionnelles,
    BIconPencilFill,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
})
export default class EditPartenaire extends Vue {
  partenaire: Partenaire | null = null;
  editPartenaire: Partenaire | null = null;

  listTypesServices: any[] = [];
  totalCountTypeServices = 0;

  mainLoading = true;
  modified = true;
  required = required;

  entityType = TypeEntiteEnum.PARTENAIRE;

  async created() {
    await this.loadTypesServices();
    await this.getPartenaire();
  }

  goToList() {
    this.$router.push({ name: "partenaires" });
  }

  async edit() {
    this.modified = !this.modified;
  }

  reset() {
    this.editPartenaire = this.partenaire;
    this.modified = true;
  }

  async getPartenaire() {
    this.mainLoading = true;
    await this.$http.myressif.partenaires
      .getById(this.$route.params.id)
      .then((res: Partenaire) => {
        this.partenaire = { ...res };
        this.editPartenaire = { ...res };
      });
    this.mainLoading = false;
  }

  async loadTypesServices() {
    await this.$http.myressif.parametres
      .getByNom("type_services_partenaire")
      .then((res: any) => {
        this.listTypesServices = res ? JSON.parse(res.valeur) : [];
      });
  }

  async askDeletePartenaire(id: string) {
    this.$bvModal
      .msgBoxConfirm("Etes-vous sûr de vouloir supprimer ce partenaire ?", {
        title: "Confirmation de suppression",
        size: "sm",
        okVariant: "primary",
        okTitle: "Oui",
        cancelTitle: "Non",
        cancelVariant: "outline-secondary",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value === true) {
          await this.confirmDelete(id);
        } else {
          return;
        }
      });
  }

  async confirmDelete(id: string) {
    await this.$http.myressif.partenaires.delete(id).then(
      async (response: string) => {
        successAlert.fire({
          title: "Suppression d'un partenaire",
          text: "Suppression effectuée avec succès",
        });
      },
      (error: any) => {
        if (
          error?.response?.data?.errors &&
          Object.keys(error.response.data.errors).length
        ) {
          errorAlert.fire({
            text: error.response.data.errors[
              Object.keys(error.response.data.errors)[0]
            ],
          });
        } else {
          errorAlert.fire({
            text: error?.response?.data?.detail ?? error.message,
          });
        }
      },
      this.$router.push({ name: "partenaires" })
    );
  }

  async submit(editPartenaire: Partenaire) {
    await this.$http.myressif.partenaires
      .update(this.$route.params.id, editPartenaire)
      .then(
        async (res: any) => {
          successAlert.fire({
            title: "Modification d'un partenaire",
            text: "Modification effectuée avec succès",
          });
          await this.getPartenaire();
          this.reset();
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      );
  }
}
