




































































































































































import {
  BSidebar,
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BSpinner,
  BFormTextarea,
  BFormCheckbox,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { Component, Prop, Vue } from "vue-property-decorator";
import { required, min, max } from "@validations";
import { CreateInformationAdditionnelle, InformationAdditionnelle } from "@/api/models/informationAdditionnelle/informationAdditionnelle";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import { IconesEnum } from "@/api/models/enums/iconesEnum";
import { CouleursEnum } from "@/api/models/enums/couleursEnum";
import { VueSelect } from "vue-select";
import { Parametre } from "@/api/models/parametres/parametre";
import { Fonction } from "@/api/models/authorization/fonctions/fonction";
import { TypeEntiteEnum } from "@/api/models/enums/typeEntiteEnum";
import { Groupe } from "@/api/models/groupes/groupe";

@Component({
  components: {
    BSidebar,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    BFormCheckboxGroup,
    VueSelect
  },
  name: "edit-information-additionnelle",
})
export default class EditInformationAdditionnelleModal extends Vue {
  @Prop({ required: true }) informationAdditionnelleId!: string
  @Prop({ required: false, default: "Modification d'une information additionnelle" })
  title!: string;
  loading = true;

  required = required;
  min = min;
  max = max;

  searchQuery = "";
  totalCount = 0;
  searchEntitesItems: any[] = []

  entitiesOptions = Object.values(TypeEntiteEnum);

  icones = Object.values(IconesEnum);
  iconesEnum = IconesEnum;

  couleurs = Object.values(CouleursEnum)
  couleursEnum = CouleursEnum;

  couleurSelected: string = "";
  iconeSelected: string = "";

  listFonctions: { text: string; value: string }[] = [];
  listGroupes: { text: string; value: string }[] = [];

  informationAdditionnelle: InformationAdditionnelle | null = null

  getIconeKey(iconeValue: any) {
    console.log(Object.keys(this.iconesEnum)[
        Object.values(this.iconesEnum).indexOf(iconeValue)
      ]);
    return Object.keys(this.iconesEnum)[
        Object.values(this.iconesEnum).indexOf(iconeValue)
      ];
  }

  getCouleurKey(couleurValue: any) {
    return Object.keys(this.couleursEnum)[
        Object.values(this.couleursEnum).indexOf(couleurValue)
      ];
  }

  test() {
    this.informationAdditionnelle = null
  }

  async onModalDisplay() {
    await this.loadData()
    this.loading = false;
  }

  async loadData() {
    this.informationAdditionnelle = null
    await this.$http.myressif.informationsAdditionnelles
      .getById(this.informationAdditionnelleId)
      .then((response: InformationAdditionnelle) => {
        this.informationAdditionnelle = response
      });

    await this.loadFonctions();
    await this.loadGroupes();
  }

  async loadGroupes() {
    var parametreNom = "";
    switch (this.informationAdditionnelle!.cibleType) {
      case TypeEntiteEnum.UTILISATEUR:
        parametreNom = "informationsadditionnelles_groupes_utilisateur";
        break;
      case TypeEntiteEnum.ENTREPRISE:
        parametreNom = "informationsadditionnelles_groupes_adherent";
        break;
      case TypeEntiteEnum.FOURNISSEUR:
        parametreNom = "informationsadditionnelles_groupes_fournisseur";
        break;
      case TypeEntiteEnum.PARTENAIRE:
        parametreNom = "informationsadditionnelles_groupes_partenaire";
      default:
        break;
    }

    var toRemove: string[] = [];
    var only: string[] = [];

    await this.$http.myressif.parametres
      .getByNom(parametreNom)
      .then((response: Parametre) => {
        var valeur = response ? JSON.parse(response.valeur) : [];
        toRemove = valeur["remove"];
        only = valeur["only"];
      });

    await this.$http.myressif.groupes
      .paginatedList(1, 100)
      .then((response: PaginatedList<Groupe>) => {
        var test = response.items.filter(
          (g) =>
            (only ? only.includes(g.id) : true) &&
            (toRemove ? !toRemove.includes(g.id) : true)
        );
        this.listGroupes = test.map(({ libelle: text, id: value }) => ({
          text,
          value,
        }));
      });
  }
  
  async loadFonctions() {
    var parametreNom = ""
    switch (this.informationAdditionnelle!.cibleType) {
      case TypeEntiteEnum.UTILISATEUR:
        parametreNom = "informationsadditionnelles_droits_utilisateur"
        break;
      case TypeEntiteEnum.ENTREPRISE:
        parametreNom = "informationsadditionnelles_droits_adherent"
        break;
      case TypeEntiteEnum.FOURNISSEUR:
        parametreNom = "informationsadditionnelles_droits_fournisseur"
        break;
      case TypeEntiteEnum.PARTENAIRE:
        parametreNom = "informationsadditionnelles_droits_partenaire"
      default:
        break;
    }

    var toRemove: string[] = []
    var only: string[] = []

    await this.$http.myressif.parametres
      .getByNom(parametreNom)
      .then((response: Parametre) => {
        var res = response ? JSON.parse(response.valeur) : [];
        toRemove = res['remove']
        only = res['only'];
      });

    await this.$http.myressif.fonctions
      .paginatedList(1, 100)
      .then((response: PaginatedList<Fonction>) => {
        var test = response.items.filter(f => (only ? only.includes(f.code) : true) && (toRemove ? !toRemove.includes(f.code) : true))
        this.listFonctions = test.map(
          ({ libelle: text, id: value }) => ({
            text,
            value,
          })
        );
      });
  }

  async submit() {
    this.loading = true;
    await this.$http.myressif.informationsAdditionnelles
      .update(this.informationAdditionnelleId, this.informationAdditionnelle)
      .then(
        async (response: any) => {
          successAlert.fire({
            timer: 3500,
            title: "Modification d'une information additionnelle",
            text: "Information modifiée avec succès",
          });
          this.$emit("reload-liste")
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(async () => {
        this.loading = false;
      });
  }
}
