var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"add-new-contact-sidebar","visible":_vm.isAddNewContactSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"hidden":_vm.resetForm,"change":function (val) { return _vm.$emit('update:is-add-new-contact-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v("Ajouter un nouveau contact")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"createFormValidation",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"nom du nouveau contact","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"for":"region"}},[_vm._v("Nom du contact : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-input',{attrs:{"trim":"","placeholder":"Nom"},model:{value:(_vm.newContact.nom),callback:function ($$v) {_vm.$set(_vm.newContact, "nom", $$v)},expression:"newContact.nom"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('b-form-group',[_c('label',{attrs:{"for":"region"}},[_vm._v("Prénom du contact : ")]),_c('b-form-input',{attrs:{"trim":"","placeholder":"Prénom"},model:{value:(_vm.newContact.prenom),callback:function ($$v) {_vm.$set(_vm.newContact, "prenom", $$v)},expression:"newContact.prenom"}})],1),_c('b-form-group',[_c('label',{attrs:{"for":"region"}},[_vm._v("Fonction : ")]),_c('b-form-input',{attrs:{"trim":"","placeholder":"Fonction"},model:{value:(_vm.newContact.fonction),callback:function ($$v) {_vm.$set(_vm.newContact, "fonction", $$v)},expression:"newContact.fonction"}})],1),_c('validation-provider',{attrs:{"name":"categorie","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',[_vm._v("Categorie")]),_c('b-form-select',{attrs:{"options":_vm.categories},model:{value:(_vm.newContact.categorie),callback:function ($$v) {_vm.$set(_vm.newContact, "categorie", $$v)},expression:"newContact.categorie"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('b-form-group',[_c('label',{attrs:{"for":"region"}},[_vm._v("Adresse email du contact : ")]),_c('b-form-input',{attrs:{"trim":"","placeholder":"Email"},model:{value:(_vm.newContact.email),callback:function ($$v) {_vm.$set(_vm.newContact, "email", $$v)},expression:"newContact.email"}})],1),_c('b-form-group',[_c('label',{attrs:{"for":"region"}},[_vm._v("Téléphone 1 du contact : ")]),_c('b-form-input',{attrs:{"trim":"","placeholder":"Téléphone"},model:{value:(_vm.newContact.telephone1),callback:function ($$v) {_vm.$set(_vm.newContact, "telephone1", $$v)},expression:"newContact.telephone1"}})],1),_c('b-form-group',[_c('label',{attrs:{"for":"region"}},[_vm._v("Téléphone 2 du contact : ")]),_c('b-form-input',{attrs:{"trim":"","placeholder":"Téléphone"},model:{value:(_vm.newContact.telephone2),callback:function ($$v) {_vm.$set(_vm.newContact, "telephone2", $$v)},expression:"newContact.telephone2"}})],1),(_vm.loading)?_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"label":"Chargement...","variant":"primary"}})],1):_vm._e(),(!_vm.loading)?_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"primary","type":"submit","disabled":invalid}},[_vm._v(" Ajouter ")]),_c('b-button',{attrs:{"type":"button","variant":"outline-secondary"},on:{"click":hide}},[_vm._v(" Annuler ")])],1):_vm._e()],1)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }