






















































































































































































import { BAlert, BButton, BButtonGroup, BPagination, BCol } from "bootstrap-vue";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { InformationAdditionnelle } from "@/api/models/informationAdditionnelle/informationAdditionnelle";
import { PaginatedList } from "@/api/models/common/paginatedList";
import AppTimeline from "@/@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@/@core/components/app-timeline/AppTimelineItem.vue";
import CreateInformationAdditionnelle from "../informationAdditionnelles/CreateModal.vue";
import EditInformationAdditionnelle from "../informationAdditionnelles/EditModal.vue";
import DetailsInformationAdditionnelle from "../informationAdditionnelles/DetailsModal.vue";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import VueSlider from "vue-slider-component";

@Component({
  components: {
    AppTimeline,
    AppTimelineItem,
    CreateInformationAdditionnelle,
    BAlert,
    BButton,
    BButtonGroup,
    BPagination,
    BCol,
    EditInformationAdditionnelle,
    DetailsInformationAdditionnelle,
    VueSlider,
  },
  name: "information-additionnelle",
  filters: {
    truncate: function (text: string, length: any, suffix: string) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
})
export default class ListInformationsAdditionnelles extends Vue {
  @Prop({ required: true }) cibleType!: string;
  @Prop({ required: true }) cibleId!: string;

  value = 50;

  perPage = 10;
  currentPage = 1;
  listInformationsAdditionnelles: PaginatedList<InformationAdditionnelle> =
    new PaginatedList<InformationAdditionnelle>();

  selectedId: string = "";

  get paginationFrom() {
    return (
      this.perPage * (this.currentPage - 1) +
      (this.listInformationsAdditionnelles.items.length ? 1 : 0)
    );
  }
  get paginationTo() {
    return (
      this.perPage * (this.currentPage - 1) +
      this.listInformationsAdditionnelles.items.length
    );
  }

  @Watch("currentPage")
  @Watch("perPage")
  @Watch("pertinentOnly")
  async paginationChanged() {
    await this.loadInformationsAdditionnelles({
      pageNumber: this.currentPage,
      pageSize: this.perPage,
    });
  }

  async created() {
    await this.loadInformationsAdditionnelles({
      pageNumber: this.currentPage,
      pageSize: this.perPage,
    });
  }

  async downloadFile(informationAditionnelleId: string, fichierId: string) {
    await this.$http.myressif.informationsAdditionnelles
      .downloadInformationAdditionnelleFile(
        informationAditionnelleId,
        fichierId
      )
      .then(
        async (response: any) => {
          window.open(response);
        },
        (error: any) => {
          if (error?.response?.data?.errors) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error.message,
            });
          }
        }
      );
  }

  async loadInformationsAdditionnelles(params: any) {
    if (!params || params?.reset) {
      this.listInformationsAdditionnelles =
        new PaginatedList<InformationAdditionnelle>();
    }
    await this.$http.myressif.informationsAdditionnelles
      .paginatedList(
        this.cibleType,
        this.cibleId,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10
      )
      .then((response: PaginatedList<InformationAdditionnelle>) => {
        this.listInformationsAdditionnelles = response;
      });
  }

  openDetailsModal(id: string) {
    this.selectedId = id;
    this.$bvModal.show("details-information-additionnelle");
  }

  openEditModal(id: string) {
    this.selectedId = id;
    this.$bvModal.show("edit-information-additionnelle");
  }

  openDeleteModal(id: string) {
    this.$bvModal
      .msgBoxConfirm(
        "Etes-vous sûr de vouloir supprimer cette information additionnelle ?",
        {
          title: "Confirmation de suppression",
          size: "sm",
          okVariant: "primary",
          okTitle: "Oui",
          cancelTitle: "Non",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        }
      )
      .then(async (value) => {
        if (value === true) {
          this.confirmRemoveInformationAdditionnelle(id);
        } else {
          return;
        }
      });
  }

  async confirmRemoveInformationAdditionnelle(id: string) {
    await this.$http.myressif.informationsAdditionnelles.delete(id).then(
      async (response: any) => {
        successAlert.fire({
          timer: 3500,
          title: "Modification d'une information additionnelle",
          text: "Information modifiée avec succès",
        });
        await this.loadInformationsAdditionnelles(null);
      },
      (error: any) => {
        if (
          error?.response?.data?.errors &&
          Object.keys(error.response.data.errors).length
        ) {
          errorAlert.fire({
            text: error.response.data.errors[
              Object.keys(error.response.data.errors)[0]
            ],
          });
        } else {
          errorAlert.fire({
            text: error?.response?.data?.detail ?? error.message,
          });
        }
      }
    );
  }
}
