





























































































































































import { PaginatedList } from "@/api/models/common/paginatedList";
import { Contrat } from "@/api/models/contrats/contrat";
import { Partenaire } from "@/api/models/partenaires/partenaire";
import {
  BButton,
  BCard,
  BCol,
  BDropdown,
  BFormSelect,
  BDropdownItem,
  BFormInput,
  BPagination,
  BRow,
  BTable,
  BBadge
} from "bootstrap-vue";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import { Entreprise } from "@/api/models/adherents/adherent";
import UpdateContrat from "./EditContrat.vue";
import CreateContrat from "./CreateContrat.vue";

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BBadge,
    UpdateContrat, 
    CreateContrat
  },
})
export default class ListContrats extends Vue {
  @Prop({ required: true }) partenaire!: Partenaire;
  perPage = 10;
  currentPage = 1;
  perPageOptions = [2, 5, 10, 25, 50, 100];
  isAddNewContratSidebarActive = false;
  isUpdateContratSidebarActive = false;
  selectedContratId = "";
  timeout: any = null;
  isSortDirDesc = false;
  sortBy = "nom";

  tableColumns = [
    { key: "avenant", sortable: true },
    { key: "nom", sortable: true },
    { key: "typeContrat", sortable: true },
    { key: "dateDemarrage", sortable: true },
    { key: "dateExpiration", sortable: true },
    { key: "actions", sortable: false }
  ];
  paginatedListContrats = new PaginatedList<Contrat>();

  async created() {
    await this.loadListContrat({
      pageNumber: this.currentPage,
      pageSize: this.perPage,
      orderBy: this.sortBy,
      orderByDescending: this.isSortDirDesc
    });
  }

  get paginationFrom() {
    return (
      this.perPage * (this.currentPage - 1) +
      (this.paginatedListContrats.items.length ? 1 : 0)
    );
  }
  get paginationTo() {
    return (
      this.perPage * (this.currentPage - 1) +
      this.paginatedListContrats.items.length
    );
  }

  @Watch("currentPage")
  @Watch("perPage")
  async paginationChanged() {
    await this.loadListContrat({
      pageNumber: this.currentPage,
      pageSize: this.perPage,
      orderBy: this.sortBy,
      orderByDescending: this.isSortDirDesc
    });
  }

  @Watch("sortBy")
  @Watch("isSortDirDesc")
  async searchContrat() {
    if(this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(async () => {
      await this.loadListContrat({
        pageNumber: this.currentPage,
        pageSize: this.perPage,
        orderBy: this.sortBy,
        orderByDescending: this.isSortDirDesc
      })
    }, 1000);
  }

  updateSort(params: any) {
    this.sortBy = params.sortBy;
    params = {
      pageNumber: this.currentPage,
      pageSize: this.perPage,
      orderBy: this.sortBy,
      orderByDescending: this.isSortDirDesc
    };
    this.isSortDirDesc = !this.isSortDirDesc;
    this.loadListContrat(params);
  }

  selectContrat(contratId: string) {
    this.selectedContratId = contratId;
    this.isUpdateContratSidebarActive = true;
  }

  async downloadFile(partenaireId: string, contratId: string) {
    await this.$http.myressif.partenaires
      .downloadContrat(partenaireId, contratId)
      .then(
        async (response: any) => {
          window.open(response);
        },
        (error: any) => {
          if (error?.response?.data?.errors) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error.message,
            });
          }
        }
      );
  }

  async loadListContrat(params: any) {
    if (!params || params?.reset) {
      this.paginatedListContrats = new PaginatedList<Contrat>();
    }
    await this.$http.myressif.partenaires
      .getListContratsByPartenaireId(
        this.$route.params.id,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? "",
        params?.orderBy,
        params?.orderByDescending
      )
      .then((response: PaginatedList<Contrat>) => {
        this.paginatedListContrats = response;
      });
  }

  askDelete(id: string) {
    this.$bvModal
      .msgBoxConfirm("Etes-vous sûr de vouloir supprimer ce contrat ?", {
        title: "Confirmation de suppression",
        size: "sm",
        okVariant: "primary",
        okTitle: "Oui",
        cancelTitle: "Non",
        cancelVariant: "outline-secondary",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value === true) {
          await this.confirmDelete(id);
        } else {
          return;
        }
      });
  }

  async confirmDelete(id: string) {
    await this.$http.myressif.partenaires
      .deleteContratPartenaire(this.$route.params.id, id)
      .then(
        async (response: string) => {
          successAlert.fire({
            title: "Suppression d'un contrat",
            text: "Suppression effectuée avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.loadListContrat(null);
      });
  }
}
