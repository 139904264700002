





























































































































import {
  BSidebar,
  BForm,
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BDropdown,
  BFormGroup,
  BDropdownItem,
  BFormInput,
  BPagination,
  BFormSelect,
  BRow,
  BTable,
  BSpinner,
  BFormDatepicker,
  BFormFile,
} from "bootstrap-vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { ContratCreateModel } from "@/api/models/contrats/contrat";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BSidebar,
    BForm,
    BFormGroup,
    BFormCheckbox,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    SearchableVueSelect,
    BFormDatepicker,
    BFormFile,
  },
  name: "create-contrat",
})
export default class CreateContrat extends Vue {
  @Prop({ required: true, type: Boolean })
  isAddNewContratSidebarActive!: boolean;
  loading = false;

  required = required;

  listTypeContrat = [];

  totalCountTypeContrat = 0;

  newContrat: ContratCreateModel = {};

  async created() {
    await this.getTypeContrat();
  }

  resetForm() {
    this.newContrat = {};
  }

  async getTypeContrat() {
    await this.$http.myressif.parametres
      .getByNom("type_contrat_partenaire")
      .then((res: any) => {
        this.listTypeContrat = res ? JSON.parse(res.valeur) : [];
      }),
      (error: any) => {
        if (
          error?.response?.data?.errors &&
          Object.keys(error.response.data.errors).length
        ) {
          errorAlert.fire({
            text: error.response.data.errors[
              Object.keys(error.response.data.errors)[0]
            ],
          });
        } else {
          errorAlert.fire({
            text: error?.response?.data?.detail ?? error.message,
          });
        }
      };
  }

  async submit() {
    this.loading = true;
    await this.$http.myressif.partenaires
      .createContratOnPartenaire(this.$route.params.id, this.newContrat)
      .then(
        async (response: any) => {
          successAlert.fire({
            timer: 3500,
            title: "Création d'un contrat",
            text: "Contrat créé avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.$emit("refresh-contrat");
        this.$emit("update:is-add-new-contrat-sidebar-active", false);
        this.loading = false;
      });
  }
}
